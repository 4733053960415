import { usePage } from "@inertiajs/vue3";
import { App } from "vue";
import type { RouteKeys, RouteParams } from "@/routes";
import reverseUrl from "@/routes";

const routes = import.meta.glob("../routes/*.ts", {
    eager: true,
    import: "default",
});

const reverse: typeof reverseUrl = <T extends RouteKeys>(
    urlName: T,
    ...args: Record<string, never> extends RouteParams<T>
        ? [args?: never]
        : [args: RouteParams<T>]
) => {
    const lang = usePage().props?.language;
    const reverse = routes[`../routes/${lang}.ts`] as typeof reverseUrl;
    return reverse(urlName, ...args);
};

const routeReverse = {
    install: (app: App) => {
        app.config.globalProperties.$reverse = reverse;
    },
};

export { reverse };
export default routeReverse;
